

const firebaseConfig = {
    apiKey: "AIzaSyDK8SDjSfEXSleCleHKPpF5lEXNqaP5GUA",
    authDomain: "matrimonial-app-cdc42.firebaseapp.com",
    projectId: "matrimonial-app-cdc42",
    storageBucket: "matrimonial-app-cdc42.appspot.com",
    messagingSenderId: "1068316719216",
    appId: "1:1068316719216:web:2d7fb4c67aed1267321cb5",
    measurementId: "G-MG7J41XCDC",
    vapidKey:"BOKshcvmo_qDhT12sljkMUsU5ccohli8zDPpt9DVMXYbTs3WaY1w32jZIqLvHI4wGx3ERLu55MnmHYCtg0OxRqI"
};


//const firebaseConfig = {
//    apiKey: "<YOUR-API-KEY>",
//    authDomain: "<YOUR-PROJECT-ID>.firebaseapp.com",
//    projectId: "<YOUR-PROJECT-ID>",
//    storageBucket: "<YOUR-PROJECT-ID>.appspot.com",
//    messagingSenderId: "<YOUR-MESSANGING-SENDER-ID>",
//    appId: "<YOUR-APP-ID>",
//    measurementId: "<YOUR-MEASUREMENT-ID>"
//};

export default firebaseConfig;
